import React from 'react'
import { graphql } from 'gatsby'
import SEOTools from '../components/SEOTools'
import { PortableText } from '@portabletext/react'
import { Col, Container, Row } from 'react-bootstrap'
import { GatsbyImage, getSrc } from 'gatsby-plugin-image'
import blocksToText from '../utilities/blocksToText'
import truncate from 'lodash/truncate'
import Author from '../components/blog/Author'
import PostListing from '../components/blog/PostListing'
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl'
import generateArticleSchemaDataFromPost from '../utilities/generateArticleSchemaDataFromPost'
import { useLocation } from '@reach/router'
import ClientRendered from '../components/ClientRendered'
import styled from 'styled-components'
import portableTextComponents from '../components/portableTextComponents'
import Layout from '../components/Layout'
import HeroUnit from '../components/HeroUnit'

/**
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
export default function BlogPostPage({ data }) {
    const location = useLocation()

    const { page } = data

    const { mainImage } = page
    const metaDescription = truncate(blocksToText(page._rawBody, true), { length: 200 })
    const metaImage = mainImage.image ? getSrc(mainImage.image.asset) : null
    const publishedAt = new Date(page.publishedAt)

    const articleStructuredData = generateArticleSchemaDataFromPost(page)

    return (
        <Layout>
            <SEOTools title={page.title} image={metaImage} description={metaDescription} location={location}>
                <script type='application/ld+json'>{JSON.stringify(articleStructuredData)}</script>
            </SEOTools>

            <HeroUnit backgroundImage={data.heroBg} className='darker-background-overlay text-white'>
                <Row className='d-flex align-items-center justify-content-center text-center my-5'>
                    <Col md='8'>
                        <span className='h1'>
                            <FormattedMessage id='blog' />
                        </span>
                    </Col>
                </Row>
            </HeroUnit>

            <Container>
                <p className='mt-5 text-secondary'>
                    {/* Render publishedat time only on client, because server time at
                        ssr might differ and cause hydration issues */}
                    <ClientRendered fallback={<>&nbsp;</>}>
                        <FadeInSpan>
                            <FormattedDate value={publishedAt} /> <FormattedTime value={publishedAt} />
                        </FadeInSpan>
                    </ClientRendered>
                </p>
                <Row className='mb-5'>
                    <Col lg='7' as='article'>
                        <h1 className='mt-5 mb-4'>{page.title}</h1>
                        {page.secondaryTitle && (
                            <p className='mb-4 mt-n3 text-secondary h2 secondary-title fw-normal'>
                                {page.secondaryTitle}
                            </p>
                        )}

                        {mainImage.image && (
                            <figure>
                                <GatsbyImage
                                    image={mainImage.image.asset.gatsbyImageData}
                                    alt={mainImage.alt}
                                    className='mt-4 mb-0 shadow-sm'
                                />
                                {mainImage.caption && (
                                    <figcaption className='text-muted fst-italic fw-light small mt-3 mb-n3'>
                                        {mainImage.caption}
                                    </figcaption>
                                )}
                            </figure>
                        )}

                        <div className='mt-5'>
                            <PortableText value={page._rawBody} components={portableTextComponents} />
                        </div>
                    </Col>
                    <Col lg={{ span: 4, offset: 1 }}>
                        <hr className='d-lg-none my-5' />
                        <Author author={page.author} />
                        {!!page.related?.length && (
                            <>
                                <hr />
                                <div className='py-3 mt-4'>
                                    <p className='h5'>
                                        <FormattedMessage id='related' />
                                    </p>
                                    <PostListing related={page.related} />
                                </div>
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

const FadeInSpan = styled.span`
    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    animation-name: fadein;
    animation-duration: 0.2s;
`

export const query = graphql`
    query ($slug: String!, $lang: String!) {
        page: sanityBlogPost(slug: { current: { eq: $slug } }, lang: { eq: $lang }) {
            _rawBody
            publishedAt
            title
            secondaryTitle
            lang
            mainImage {
                alt
                caption
                image {
                    asset {
                        gatsbyImageData(placeholder: BLURRED, width: 1320)
                    }
                }
            }
            author {
                _rawBio
                image {
                    asset {
                        gatsbyImageData(placeholder: BLURRED, width: 200, aspectRatio: 1)
                    }
                }
                name
                linkedIn
            }
            related {
                title
                _rawBody
                slug {
                    current
                }
                lang
                mainImage {
                    alt
                    image {
                        asset {
                            gatsbyImageData(placeholder: BLURRED, width: 100, aspectRatio: 1)
                        }
                    }
                }
            }
        }
        heroBg: file(relativePath: { eq: "H3lloWomanLarge.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
    }
`
